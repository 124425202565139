/* bannerSec start */
.bannerSec {
  background: url('../Images/Backgrounds/baneerBg.png');
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  padding-bottom: 100px;
}

.bannerHead img {
  max-width: 90%;
}

.bannerSec::after,
.aboutImg::after,
.faqImg::after {
  content: '';
  display: block;
  width: 50%;
  height: 90%;
  border-radius: 50%;
  background: radial-gradient(#0070f747, #040416 70%);
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

.bannerPck {
  width: 35%;
  min-width: 200px;
  border-radius: 50%;
  margin: auto;
  position: relative;
  margin-top: -90px;
}

.bannerPck .btn {
  position: absolute;
  background-color: transparent !important;
  border: none;
  color: #000;
  padding: 0;
  width: 25%;
  left: 38%;
  top: 30%;
  font-size: 18px;
  text-transform: uppercase;
  transform: rotate(8deg);
}

.bannerNav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: -145px;
}

.bannerNav .bnrNavLink {
  position: relative;
  display: block;
}

.bannerNav .bnrNavLink img {
  width: 200px;
}

.bannerNav .bnrNavLink span {
  font-size: 20px;
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0;
  top: 27%;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.bannerNav .bnrNavLink:hover span {
  opacity: 1;
}

.bannerNav .bnrNavLeft,
.bannerNav .bnrNavRight {
  display: flex;
  flex-wrap: wrap;
  text-transform: capitalize;
}

.bannerNav .bnrNavLeft .bnrNavLink:first-child,
.bannerNav .bnrNavRight .bnrNavLink:last-child {
  transform: translateY(-100px);
}

.bannerNav .bnrNavLeft .bnrNavLink:last-child img {
  transform: rotateY(180deg);
}

.bannerNav .bnrNavRight .bnrNavLink:first-child img {
  transform: rotateY(180deg);
}

/* bannerSec end */

/* partnerSec start */
.mainTitle {
  font-size: 56px;
  font-family: 'sweetMagic';
  text-align: center;
}

.partnerDiv {
  background: url('../Images/Backgrounds/partnerBg.png') no-repeat center center;
  background-size: contain;
  padding: 40px 10px 60px;
}

/* partnerSec end */

/* aboutSec start */
.aboutSec {
  padding: 150px 0 100px;
}

.aboutImg {
  z-index: 1;
  position: relative;
}

.aboutImg::after,
.faqImg::after {
  width: 70%;
  height: 100%;
  top: 50%;
  bottom: unset;
  transform: translate(-50%, -50%) scale(1.5);
}

.aboutTxt {
  font-size: 20px;
  line-height: 2;
  color: #000;
  background: url('../Images/Backgrounds/aboutBg.png') no-repeat center center;
  background-size: contain;
  padding: 100px 95px;
}

/* aboutSec end */

/* availableSec start */
.availBg {
  width: 97%;
  max-width: 330px;
  display: block;
  margin: auto;
}

.availImg {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 25%;
  max-width: 70%;
}

/* availableSec end */

/* tokenSec start */
.tokenSec {
  padding: 100px 0;
}

.contractAddress {
  width: fit-content;
  margin: auto;
  background: url('../Images/Backgrounds/tokenAddress.png') no-repeat center center;
  background-size: contain;
  text-align: center;
  padding: 25px 100px;
  color: #000;
  font-size: 20px;
  word-break: break-all;
}

.contractAddress .btn {
  background-color: #164AFE;
  border-radius: 10px;
  padding: 5px 20px;
}

.tokenDiv .tokenTxt {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  left: 0;
  top: 50%;
  font-size: 20px;
  color: #000;
}

.tokenDiv img {
  display: block;
  width: 100%;
  max-width: 300px;
  margin: auto;
}

/* tokenSec end */

/* buySec start */
.buyDiv {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: url('../Images/Backgrounds/aboutBg.png') no-repeat center center;
  background-size: contain;
  position: relative;
  padding: 100px 75px;
  text-align: center;
  color: #000;
  font-size: 18px;
  margin: 0 15px;
}

.buyNum {
  position: absolute;
  left: 0;
  top: 10px;
}

.buyDiv .buyTxt h4::after {
  content: '';
  display: block;
  width: 70%;
  height: 2px;
  margin: 15px auto;
  background: var(--orange);
}

.buySecBg {
  font-family: 'sweetMagic';
  color: #040416;
  font-size: 22vw;
  text-align: center;
  text-shadow: 2px 0 #292939, -2px 0 #292939, 0 2px #292939, 0 -2px #292939,
    1px 1px #292939, -1px -1px #292939, 1px -1px #292939, -1px 1px #292939;

  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

/* buySec end */

/* communitySec start */
.communitySec {
  padding: 100px 0;
}

.commRow {
  background: url('../Images/Backgrounds/commBg.png') no-repeat center center;
  background-size: contain;
  padding: 90px;
  color: #000;
  text-align: center;
  font-size: 20px;
}

/* communitySec end */

/* faqSec start */
.faqDiv .accordion-item {
  border-radius: 20px;
  border: 4px solid var(--brown);
  font-size: 18px;
  margin-bottom: 20px;
}

.faqDiv .accordion-button {
  background: transparent;
  box-shadow: none;
  font-size: 22px;
  color: #000;
  padding: 12px 20px;
}

.faqDiv .accordion-button::after {
  content: '+';
  font-family: 'circular';
  background: var(--brown);
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 35px;
  font-size: 41px;
  /* font-weight: bold; */
  border-radius: 10px;
  color: #fff;
  transform: none;
}

.faqDiv .accordion-button:not(.collapsed)::after {
  content: '-';
}

.faqImg {
  /* max-width: 400px; */
  margin: auto;
  padding-top: 40px;
}

.faqImg::after {
  width: 100%;
  left: 35%;
}

/* faqSec end */