/* header start */
.mainHeader {
  background: url('../Images/Backgrounds/headerBg.png') no-repeat bottom center;
  background-size: 100% 100%;
  min-height: 195px;
}

.mainNav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 40px 95px 20px;
  gap: 15px;
  margin: auto;
}

.navMenu .navLink {
  display: block;
  background: url('../Images/Backgrounds/HeaderBtn.png') no-repeat center center;
  padding: 20px 40px;
  background-size: contain;
  text-align: center;
}

.headerRight .headerBtn {
  background-color: transparent !important;
  background: url('../Images/Backgrounds/headerBtn2.png') no-repeat center center;
  width: 170px;
  height: 65px;
  padding: 5px;
  background-size: contain;
  border: none;
  text-transform: uppercase;
  color: #000;
  font-size: 18px;
}

/* header end */

/* footer start */
.footersec {
  background: url('../Images/Backgrounds/footerBg.png') no-repeat top center;
  background-size: cover;
  padding: 150px 0 40px;
  margin-top: 100px;
}

/* footer end */

@media screen and (min-width: 1600px) {
  .mainNav {
    padding: 40px 20px 20px;
  }

  .footersec {
    padding-top: 175px;
  }
}

@media screen and (max-width: 1399px) {
  .mainHeader {
    background-size: cover;
    min-height: 160px;
  }

  .mainNav {
    padding: 20px 35px;
  }

  .footersec {
    padding: 120px 0 20px;
  }
}

@media screen and (max-width: 1199px) {
  .mainNav {
    padding: 20px 10px;
  }
}

@media screen and (max-width: 991px) {
  .mainNav {
    padding: 20px;
  }

  .navMenu .navLink {
    padding: 15px 35px;
  }

  .headerRight .headerBtn {
    width: 140px;
    height: 55px;
    font-size: 16px;
  }
}

@media screen and (max-width: 767px) {}

@media screen and (max-width: 575px) {
  .mainNav {
    justify-content: center;
    padding: 10px;
  }

  .navMenu .navLink {
    padding: 15px 25px;
  }

  .navMenu .navLink img {
    width: 80%;
  }

  .headerRight .headerBtn {
    height: 50px;
  }

  .footersec {
    font-size: 14px;
  }
}

@media screen and (max-width: 425px) {
  .mainNav {
    gap: 5px;
  }
}