@media screen and (min-width: 1600px) {

  /* .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1550px;
  } */

  .container {
    max-width: 1550px;
  }

  .bannerNav .bnrNavLeft,
  .bannerNav .bnrNavRight {
    gap: 50px;
  }

  .aboutTxt {
    padding: 100px 150px 150px;
  }

  .buyDiv {
    padding: 100px;
  }

  .commRow {
    padding: 90px 250px;
  }

  .faqImg {
    padding-top: 0;
  }
}

@media screen and (max-width: 1399px) {
  .bannerNav .bnrNavLink img {
    width: 170px;
  }

  .bannerNav .bnrNavLink span {
    font-size: 18px;
  }

  .aboutTxt {
    background-size: 100% 100%;
    padding: 70px 75px 100px;
    line-height: 1.8;
    font-size: 18px;
  }

  .buyDiv {
    padding: 75px;
    margin: 0;
  }

  .faqDiv .accordion-button {
    font-size: 19px;
    padding: 10px 20px;
  }

  .faqDiv .accordion-item {
    font-size: 17px;
  }
}

@media screen and (max-width: 1199px) {
  .aboutTxt {
    padding: 70px 75px 65px;
    line-height: 1.5;
    font-size: 17px;
  }

  .bannerPck .btn {
    font-size: 16px;
  }

  .bannerNav .bnrNavLeft .bnrNavLink:last-child,
  .bannerNav .bnrNavRight .bnrNavLink:first-child {
    margin: 0 -20px;
  }

  .aboutSec {
    padding: 65px 0;
  }

  .contractAddress {
    font-size: 18px;
  }

  .tokenDiv .tokenTxt {
    font-size: 18px;
  }

  .tokenDiv .tokenTxt h2 {
    font-size: 24px;
  }

  .buyNum {
    left: -10px;
    top: 30px;
  }

  .commRow {
    padding: 85px;
    font-size: 18px;
  }

  .commRow .mainTitle {
    font-size: 52px;
  }

  .faqDiv .accordion-item {
    font-size: 16px;
  }

  .faqDiv .accordion-button {
    font-size: 17px;
    padding: 7px 15px;
  }

  .faqImg::after {
    left: 30%;
  }
}

@media screen and (max-width: 991px) {
  .bannerNav {
    margin-top: 0;
  }

  .bannerPck {
    width: 45%;
    margin-top: -60px;
  }

  .bannerNav .bnrNavLink span {
    opacity: 1;
  }

  .aboutSec {
    padding: 30px 0 50px;
  }

  .aboutTxt {
    padding: 70px 105px 65px;
    text-align: center;
  }

  .aboutImg img {
    max-width: 450px;
    margin-bottom: 35px;
  }

  .aboutImg::after,
  .faqImg::after {
    width: 50%;
    height: 85%;
    transform: translate(-50%, -50%) scale(1.3);
    background: radial-gradient(#0070f769, #040416 70%);
  }

  .contractAddress {
    padding: 20px 5px;
    background-size: 100% 100%;
  }

  .buyDiv {
    padding: 105px 135px;
  }

  .buyNum {
    left: 10%;
  }

  .communitySec {
    padding: 50px 0;
  }

  .commRow {
    padding: 0 12px;
    font-size: 18px;
    background: none;
  }

  .commTxt {
    background: url('../Images/Backgrounds/commBg.png') no-repeat center center;
    background-size: contain;
    padding: 90px;
  }

  .commImg {
    max-width: 335px;
    margin: 0 auto;
  }

  .faqImg {
    max-width: 500px;
  }
}

@media screen and (max-width: 767px) {
  .bannerNav .bnrNavLink img {
    width: 125px;
  }

  .bannerPck .btn {
    font-size: 14px;
  }

  .bannerNav .bnrNavLink span {
    font-size: 14px;
  }

  .aboutTxt {
    padding: 70px;
    font-size: 16px;
  }

  .contractAddress {
    padding: 20px 5px;
    font-size: 13px;
  }

  .contractAddress .btn {
    font-size: 14px;
    padding: 2px 12px;
  }

  .buyDiv {
    padding: 75px;
  }

  .buyNum {
    left: 0;
  }

  .commRow .mainTitle {
    font-size: 40px;
  }

  .commRow {
    font-size: 16px;
    background: none;
  }

  .commTxt {
    padding: 50px;
    background-size: 100% 100%;
  }

  .faqDiv .accordion-button {
    font-size: 16px;
  }

  .faqDiv .accordion-item {
    font-size: 15px;
  }

  .faqDiv .accordion-button::after {
    width: 35px;
    height: 35px;
    line-height: 30px;
    font-size: 35px;
  }
}

@media screen and (max-width: 575px) {

  .bannerPck {
    width: 75%;
    margin-top: -30px;
  }

  .bannerNav {
    justify-content: center;
  }

  .bannerNav .bnrNavLink {
    transform: none !important;
    margin: 10px !important;
  }

  .bannerSec {
    padding-bottom: 50px;
  }

  .bannerSec::after {
    width: 85%;
    height: 60%;
    bottom: unset;
    top: 10%;
  }

  .mainTitle {
    font-size: 46px;
  }

  .partnerDiv {
    padding: 30px 10px 40px;
  }

  .aboutSec .mainTitle {
    font-size: 42px;
  }

  .aboutTxt {
    padding: 70px 10%;
    font-size: 14px;
  }

  .availBg {
    width: 92%;
    max-width: 300px;
  }

  .contractAddress span {
    display: block;
  }

  .tokenSec {
    padding: 50px 0;
  }

  .tokenDiv {
    max-width: 260px;
    margin: auto;
  }

  .tokenDiv .tokenTxt {
    top: 55%;
  }

  .buyDiv {
    padding: 15%;
    font-size: 16px;
  }

  .buyNum {
    width: 15%;
  }

  .buyDiv h4 {
    font-size: 20px;
  }

  .buyDiv .buyTxt h4::after {
    margin: 10px auto;
  }

  .communitySec {
    padding-top: 10px;
  }

  .commImg {
    max-width: 300px;
  }

  .commRow .mainTitle {
    font-size: 32px;
  }

  .commRow {
    font-size: 14px;
  }

  .faqImg {
    max-width: 350px;
  }
}

@media screen and (max-width: 425px) {
  .contractAddress {
    padding: 20px 0px;
  }

  .contractAddress span {
    font-size: 10px;
  }

  .buyDiv {
    padding: 15% 12%;
    font-size: 14px;
    background-size: 100% 100%;
  }

  .buyDiv h4 {
    font-size: 18px;
  }

  .commTxt {
    padding: 50px 15px;
  }

  .commRow .mainTitle {
    padding: 0 35px;
  }
}