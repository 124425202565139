@import url('https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap');
/* font-family: "Gloria Hallelujah", cursive; */

@font-face {
  font-family: 'sweetMagic';
  src: url('../Fonts/SweetMagic.otf');
}

:root {
  --brown: #D78E46;
  --orange: #FD7D00;
  --green: #82B112;
  --blue-shadow: #0070F7;
}

body {
  color: #fff;
  min-height: 100vh;
  overflow-x: hidden;
  background-color: #040416;
  transition: all 0.3s ease-in-out;
  font-weight: 400;
}

* {
  font-family: "Gloria Hallelujah", cursive;
  /* font-family: "Jost", sans-serif; */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: unset;
}

p {
  margin-bottom: 10px;
}

img {
  max-width: 100%;
  object-fit: cover;
}

ul {
  list-style-type: disc;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}